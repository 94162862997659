.politica-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}
    .politica-container-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 93.5%;
        margin: auto;
    }
        @media only screen and (max-width: 37.5em) {
            .politica-container-main {
                width: 90.5%;
            }
        }
        .politica-container-main-title {
            color: var(--gray700);
            margin-bottom: 32px;
            text-align: center;
        }
        .politica-container-main-subTitle {
            color: var(--gray700);
            text-align: center;
            margin-bottom: 32px;
        }
        .politica-container-main-google {
            width: 100%;
        }
            @media only screen and (max-width: 37.5em) {
                .politica-container-main-google {
                    width: 90%;
                }
            }
        .politica-container-main-politics {
            margin-top: 24px;
            width: 100%;
        }
            @media only screen and (max-width: 37.5em) {
                .politica-container-main-politics {
                    width: 90%;
                }
            }