.FormFieldGeo-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    .FormFieldGeo-container-title {
        color: var(--gray700);
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .FormFieldGeo-container-inputs {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 6px;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldGeo-container-inputs {
                flex-direction: column;
            }}
        .FormFieldGeo-container-inputs > * { 
            width: 31%;
            margin-bottom: 30px;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldGeo-container-inputs > * { 
                    width: 100%;
                }}
        .FormFieldGeo-container-inputs-wrapper {
            position: relative;
        }
            .FormFieldGeo-container-inputs-wrapper-error {
                color: var(--statusErrorPrimary);
                position: absolute;
            }
        .FormFieldGeo-container-inputs-selectorWrapper {
            display: flex;
            flex-direction: column;
            width: 31%;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldGeo-container-inputs-selectorWrapper {
                    width: 100%;
                }}
            .FormFieldGeo-container-inputs-selectorWrapper-title {
                color: var(--customTextInputLabel);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0;
                margin-bottom: 10px;
                display: flex;
                flex-direction: row;
            }
            .FormFieldGeo-container-inputs-selectorWrapper-title ul {
                font-style: normal;
                font-weight: bold;
                font-size: 1.25rem;
                line-height: 24px;
                color: var(--statusErrorPrimary);
                margin: 0;
                padding: 0;
            }
        .FormFieldGeo-container-inputs-part {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 31%;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldGeo-container-inputs-part {
                    width: 100%;
                }}
            .FormFieldGeo-container-inputs-part > * {
                width: 31%;
            }
    .FormFieldGeo-container-map {
        width: 100%;
        height: 284px;
        position: relative;
    }
        .FormFieldGeo-container-map-markerContainer {
        }
    .FormFieldGeo-container-map-loaderWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9;
        background-color: rgba(0, 0, 0, 0.1);
    }
        .FormFieldGeo-container-map-loaderWrapper-loader {
            width: 50px;
            padding: 8px;
            aspect-ratio: 1;
            border-radius: 50%;
            background: #25b09b;
            --_m: 
            conic-gradient(#0000 10%,#000),
            linear-gradient(#000 0 0) content-box;
            -webkit-mask: var(--_m);
                    mask: var(--_m);
            -webkit-mask-composite: source-out;
                    mask-composite: subtract;
            animation: l3 1s infinite linear;
        }
        @keyframes l3 {to{transform: rotate(1turn)}}