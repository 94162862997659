.Footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--gra50);
}
  .Footer-container-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 224px;
    background-color: var(--gray700);
    padding: 30px 0;
  }
    .Footer-container-wrap-main {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .Footer-container-wrap-content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
      .Footer-container-wrap-content-links {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
        .Footer-container-wrap-content-links-logo {
          width: fit-content;
          height: 50px;
          cursor: pointer;
        }
        .Footer-container-wrap-content-links-list {
          display: flex;
          justify-content: space-between;
          justify-self: flex-end;
          align-items: flex-end;
          margin-top: 24px;
        }
          .Footer-container-wrap-content-links-list img {
            width: 36px;
            height: 36px;
            cursor: pointer;
            padding-right: 10px;
          }
      .Footer-container-wrap-content-section {
        display: flex;
        flex-direction: column;
      }
        .Footer-container-wrap-content-section-title {
          color: var(--gray300);
          font-family: "Lato", sans-serif;
          margin: 0;
          margin-bottom: 10px;
        }
        .Footer-container-wrap-content-section-link {
          color: var(--primary200);
          font-family: "Lato", sans-serif;
          margin: 0;
          margin-top: 8px;
          cursor: pointer;
        }
        .Footer-container-wrap-content-section-altlink {
          color: var(--secondary500);
          font-family: "Lato", sans-serif;
          margin: 0;
          margin-top: 8px;
          cursor: pointer;
        }
        .Footer-container-wrap-content-section-info {
          color: var(--gray200);
          font-family: "Lato", sans-serif;
          margin: 0;
          margin-top: 8px;
        }
    .Footer-container-wrap-info {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
    }
      .Footer-container-wrap-info-desc {
        color: var(--gray300);
        font-size: 12px;
        margin: 0;
      }
      .Footer-container-wrap-info-rights {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
        .Footer-container-wrap-info-rights-law {
          color: var(--gray300);
          font-family: "Lato", sans-serif;
          margin: 0;
          margin-top: 24px;
        }
        .Footer-container-wrap-info-rights-power {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
          .Footer-container-wrap-info-rights-power-text {
            color: var(--gray300);
            font-family: "Lato", sans-serif;
            margin: 0;
            margin-top: 24px;
          }
          .Footer-container-wrap-info-rights-power-link {
            color: var(--secondary500);
            font-family: "Lato", sans-serif;
            margin: 0;
            margin-top: 24px;
            cursor: pointer;
            z-index: 999;
          }
  .Footer-container-legals {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
    .Footer-container-legals-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 90%;
      padding: 30px 0;
      height: 149px;
    }
    .Footer-container-legals-title {
      color: var(--primary500);
      font-family: Roboto, sans-serif;
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .Footer-container-legals-data {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
      .Footer-container-legals-data-first {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 65%;
      }
        .Footer-container-legals-data-first-noColorCont {
          display: flex; 
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 45%;
        }
          .Footer-container-legals-data-first-noColorCont-noColor {
            color: #4A4A49;
            text-align: center;
            font-family: Roboto, sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        .Footer-container-legals-data-first-colorCont {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 45%;
        }
          .Footer-container-legals-data-first-colorCont-color {
            color: #29ABE2;
            font-family: Roboto, sans-serif;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        .Footer-container-legals-data-first-line {
          height: 50px;
          width: 0.5px;
          background-color: #000;
        }
      .Footer-container-legals-data-superImg {
        width: 20%;
      }
        .Footer-container-legals-data-superImg-img {
          width: 100%;
        }
    .Footer-container-legals-publicidad {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
      .Footer-container-legals-publicidad-textOne {
        color: #4A4A49;
        font-family: Roboto, sans-serif;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 70%;
      }
      .Footer-container-legals-publicidad-textTwo {
        color: #4A4A49;
        font-family: Roboto, sans-serif;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

@media screen and (max-width: 600px) {
  .Footer-container-legals-publicidad {
    flex-direction: column;
  }
    .Footer-container-legals-publicidad-textOne {
      width: 100%;
    }
    .Footer-container-legals-publicidad-textTwo {
      width: 100%;
      margin-top: 10px;
    }
  .Footer-container {
    justify-content: flex-start;
  }
    .Footer-container-wrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 550px;
    }
      .Footer-container-wrap-content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
      .Footer-container-wrap-content-links {
        align-items: center;
        justify-content: center;
        margin: auto;
      }
        .Footer-container-wrap-content-links-list {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          align-self: center;
          margin-bottom: -32px;
        }
        .Footer-container-wrap-content-section-title {
          margin-top: 56px;
        }
        
        .Footer-container-wrap-info-rights {
          flex-direction: column;
          align-items: flex-start;
        }
  .Footer-container-wrap-info-rights-law {
    text-align: center;
    opacity: 0.5;
    font-size: 10px;
  }
  .Footer-container-wrap-info-rights-power-text {
    text-align: center;
    opacity: 0.5;
    font-size: 10px;
    margin-top: 0px;
  }
  .Footer-container-wrap-info-rights-power-link {
    margin-top: 0px;
  }

  .Footer-container-legals-wrap {
    height: 420px;
  }
  .Footer-container-legals-data {
    flex-wrap: wrap;
  }
  .Footer-container-legals-data-first {
    flex-direction: column;
    width: 100%;
  }
  .Footer-container-legals-data-first-noColorCont {
    width: 100%;
  }
  .Footer-container-legals-data-first-colorCont {
    margin: 20px 0;
    width: 100%;
  }
  .Footer-container-legals-data-superImg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
}
