.verMasModal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 464px;
}
    .verMasModal-container-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 16px;
        overflow-y: overlay;
    }
        .verMasModal-container-info-text {
            color: var(--gray700);
        }
        .verMasModal-container-info-textAlt {
            color: var(--gray500);
        }
    .verMasModal-container-title {
        color: var(--gray700);
        margin-bottom: 16px;
    }
    .verMasModal-container-close {
        margin-left: auto;
        cursor: pointer;
    }
    .verMasModal-container-boton {
        width: 20%;
        cursor: pointer;
    }
        @media only screen and (max-width: 37.5em) {
            .verMasModal-container-boton {
                width: 80%;
            }}