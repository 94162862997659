.semiSimpleCreditCardConfigComponent-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 32px;
}
    @media only screen and (max-width: 37.5em) {
        .semiSimpleCreditCardConfigComponent-container {
            width: 95%;
            flex-wrap: nowrap;
            flex-direction: column;
        }}
    .semiSimpleCreditCardConfigComponent-container > * {
        width: 31%;
        margin-bottom: 30px;
    }
        @media only screen and (max-width: 37.5em) {
            .semiSimpleCreditCardConfigComponent-container > * {
                width: 100%;
            }}
    .semiSimpleCreditCardConfigComponent-container-optionWrapper {
        display: flex;
        flex-direction: column;
        width: 31%;
        margin-bottom: 30px;
    }
        @media only screen and (max-width: 37.5em) {
            .semiSimpleCreditCardConfigComponent-container-optionWrapper {
                width: 100%;
            }}
        .semiSimpleCreditCardConfigComponent-container-optionWrapper-title {
            color: var(--customTextInputLabel);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
        }
        .semiSimpleCreditCardConfigComponent-container-optionWrapper-title ul {
            font-style: normal;
            font-weight: bold;
            font-size: 1.25rem;
            line-height: 24px;
            color: var(--statusErrorPrimary);
            margin: 0;
            padding: 0;
        }
        .semiSimpleCreditCardConfigComponent-container-optionWrapper-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
            .semiSimpleCreditCardConfigComponent-container-optionWrapper-list-item {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
                .semiSimpleCreditCardConfigComponent-container-optionWrapper-list-item-circle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 18px;
                    width: 18px;
                    border-radius: 25px;
                    margin-right: 5px;
                    cursor: pointer;
                }
                    .semiSimpleCreditCardConfigComponent-container-optionWrapper-list-item-circle-inner {
                        height: 12px;
                        width: 12px;
                        background-color: var(--secondary500);
                        border-radius: 25px;
                        transition: all 0.5s ease;
                    }
                .semiSimpleCreditCardConfigComponent-container-optionWrapper-list-item-text {
                    color: var(--gray500);
                }
    .semiSimpleCreditCardConfigComponent-container-selectorWrapper {
        display: flex;
        flex-direction: column;
        width: 31%;
        margin-bottom: 30px;
    }
        @media only screen and (max-width: 37.5em) {
            .semiSimpleCreditCardConfigComponent-container-selectorWrapper {
                width: 100%;
            }}
        .semiSimpleCreditCardConfigComponent-container-selectorWrapper-title {
            color: var(--customTextInputLabel);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
        }
        .semiSimpleCreditCardConfigComponent-container-selectorWrapper-title ul {
            font-style: normal;
            font-weight: bold;
            font-size: 1.25rem;
            line-height: 24px;
            color: var(--statusErrorPrimary);
            margin: 0;
            padding: 0;
        }
    .semiSimpleCreditCardConfigComponent-container-invis {
        width: 31%;
        margin-bottom: 30px;
    }