.categoriaButton-container {
    display: flex;
    flex-direction: column;
}
    .categoriaButton-container-box {
        display: flex;
        height: 264px;
        width: 264px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--primary200);
        transition: all 0.25s ease-in;
        cursor: pointer;
        overflow: hidden;
        position: relative;
    }
        .categoriaButton-container-box:hover {
            transform: scale(0.95);
        }
        .categoriaButton-container-box-image {
            width: 20%;
            z-index: 2;
            margin-bottom: 20px;
        }
        .categoriaButton-container-box-backGroundImage {
            height: 100%;
            width: 100%;
            position: absolute;
        }
        .categoriaButton-container-box-text {
            text-align: center;
            color: var(--white);
            z-index: 2;
        }
    .categoriaButton-container-aditionalData {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
    }
        .categoriaButton-container-aditionalData-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 16px;
        }
            .categoriaButton-container-aditionalData-item-name {
                color: var(--gray600);
                margin-left: 8px;
            }