.checkoutDetailsProduct-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: -20px;
    margin-bottom: 100px;
}
    @media only screen and (max-width: 37.5em) {
        .checkoutDetailsProduct-container {
            display: none;
        }}
    .checkoutDetailsProduct-container-period {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--secondary200); 
        border-radius: 0px 0px 10px 10px;
        padding: 5px;
        width: fit-content;
    }
        .checkoutDetailsProduct-container-period-text {
            text-align: center;
            color: var(--secondary500);
        }
    .checkoutDetailsProduct-container-data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 30px;
    }
        .checkoutDetailsProduct-container-data-texts {
            display: flex;
            flex-direction: column;
        }
            .checkoutDetailsProduct-container-data-texts-title {
                color: var(--gray400);
                margin-bottom: 8px;
            }
            .checkoutDetailsProduct-container-data-texts-plan {
                color: var(--gray700);
                margin-bottom: 32px;
            }
            .checkoutDetailsProduct-container-data-texts-insurer {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
                .checkoutDetailsProduct-container-data-texts-insurer-text {
                    color: var(--gray500);
                    margin-right: 8px;
                }
                .checkoutDetailsProduct-container-data-texts-insurer-image {
                    width: 60%;
                }
        .checkoutDetailsProduct-container-data-image {
            display: flex;
            height: 120px;
            width: 120px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            position: relative;
        }
            .checkoutDetailsProduct-container-data-image-image {
                width: 25%;
                z-index: 2;
                margin-bottom: 5px;
            }
            .checkoutDetailsProduct-container-data-image-text {
                text-align: center;
                color: var(--white);
                z-index: 2;
                font-size: 10px;
                font-style: normal;
                font-weight: 700;
                line-height: 8px;
            }
            .checkoutDetailsProduct-container-data-image-backGroundImage {
                height: 100%;
                width: 100%;
                position: absolute;
            }