.FormFieldPersonDatosFinaer-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    @media only screen and (max-width: 37.5em) {
        .FormFieldPersonDatosFinaer-container {
            justify-content: center;
            align-items: center;
            width: 100%;
            align-self: center;
            margin: 0 auto;
        }}
    .FormFieldPersonDatosFinaer-container-titulo {
        color: var(--gray700);
        margin: 24px 0;
        margin-right: auto;
    }
    .FormFieldPersonDatosFinaer-container-list {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }
        .FormFieldPersonDatosFinaer-container-list > *{
            width: 31%;
            margin-bottom: 30px;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldPersonDatosFinaer-container-list > *{
                    width: 100%;
                }}
        .FormFieldPersonDatosFinaer-container-list-list {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 31%;
            margin-bottom: 30px;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldPersonDatosFinaer-container-list-list {
                    width: 100%;
                }}
            .FormFieldPersonDatosFinaer-container-list-list > * { 
                width: 31%;
            }
        .FormFieldPersonDatosFinaer-container-list-selectorWrapper {
            display: flex;
            flex-direction: column;
            width: 31%;
            margin-bottom: 30px;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldPersonDatosFinaer-container-list-selectorWrapper {
                    width: 100%;
                }}
            .FormFieldPersonDatosFinaer-container-list-selectorWrapper-title {
                color: var(--customTextInputLabel);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0;
                margin-bottom: 10px;
                display: flex;
                flex-direction: row;
            }
            .FormFieldPersonDatosFinaer-container-list-selectorWrapper-title ul {
                font-style: normal;
                font-weight: bold;
                font-size: 1.25rem;
                line-height: 24px;
                color: var(--statusErrorPrimary);
                margin: 0;
                padding: 0;
            }
        .FormFieldPersonDatosFinaer-container-list-customInput {
            width: 31%;
            margin-bottom: 30px;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldPersonDatosFinaer-container-list-customInput {
                    width: 100%;
                }}
            .FormFieldPersonDatosFinaer-container-list-customInput-label {
                display: flex;
                flex-direction: row;
            }
                .FormFieldPersonDatosFinaer-container-list-customInput-label-text {
                    color: var(--customTextInputLabel);
                }
                .FormFieldPersonDatosFinaer-container-list-customInput-label-requiered {
                    color: var(--statusErrorPrimary);
                }
            .FormFieldPersonDatosFinaer-container-list-customInput-inputContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
                .FormFieldPersonDatosFinaer-container-list-customInput-inputContainer-inputWrapper {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-bottom: 1px solid var(--gray300);
                }
                    .FormFieldPersonDatosFinaer-container-list-customInput-inputContainer-inputWrapper-prefix {
                        color: var(--gray400);
                        width: fit-content;
                    }
                    .FormFieldPersonDatosFinaer-container-list-customInput-inputContainer-inputWrapper-input {
                        border: none;
                        background-color: transparent;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 1.25rem;
                        line-height: 24px;
                        color: var(--gray700);
                        width: 100%;
                        font-family: Lato, sans-serif;
                        padding: 10px;
                        padding-right: 0px;
                        padding-left: 5px;
                    }
                    .FormFieldPersonDatosFinaer-container-list-customInput-inputContainer-inputWrapper-input:focus {
                        outline: none;
                    }
        .FormFieldPersonDatosFinaer-container-list-optionWrapper {
            display: flex;
            flex-direction: column;
            width: 31%;
            margin-bottom: 30px;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldPersonDatosFinaer-container-list-optionWrapper {
                    width: 100%;
                }}
            .FormFieldPersonDatosFinaer-container-list-optionWrapper-title {
                color: var(--customTextInputLabel);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0;
                margin-bottom: 10px;
                display: flex;
                flex-direction: row;
            }
            .FormFieldPersonDatosFinaer-container-list-optionWrapper-title ul {
                font-style: normal;
                font-weight: bold;
                font-size: 1.25rem;
                line-height: 24px;
                color: var(--statusErrorPrimary);
                margin: 0;
                padding: 0;
            }
            .FormFieldPersonDatosFinaer-container-list-optionWrapper-list {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
                .FormFieldPersonDatosFinaer-container-list-optionWrapper-list-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                    .FormFieldPersonDatosFinaer-container-list-optionWrapper-list-item-circle {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 18px;
                        width: 18px;
                        border: 2px solid var(--secondary500);
                        border-radius: 25px;
                        margin-right: 5px;
                        cursor: pointer;
                    }
                        .FormFieldPersonDatosFinaer-container-list-optionWrapper-list-item-circle-inner {
                            height: 12px;
                            width: 12px;
                            background-color: var(--secondary500);
                            border-radius: 25px;
                            transition: all 0.5s ease;
                        }  
                    .FormFieldPersonDatosFinaer-container-list-optionWrapper-list-item-text {
                        color: var(--gray500);
                    } 