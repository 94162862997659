.burgerMenu-container { 
    display: flex;
    flex-direction: column;
    width: 30%;
    max-width: 400px;
    min-width: 300px;
    height: 100%;
    z-index: 10000000;
    position: fixed;
    transition: all 1s ease;
    background-color: var(--accent400);
}
    @media only screen and (max-width: 37.5em) {
        .burgerMenu-container { 
            width: 70%;
            min-width: unset;
            max-width: unset;
        }
    }
    .burgerMenu-container-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
    }