.checkoutFinAdmin-container {
    width:100%;
    height:100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
    .checkoutFinAdmin-container-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40%;
        z-index: 2;
        margin-bottom: -120px;
    }
    @media only screen and (max-width: 37.5em) {
        .checkoutFinAdmin-container-main {
            width: 90%;
        }}
        .checkoutFinAdmin-container-main-bot {
            margin-bottom: 16px;
            width: 40%;
        }
        .checkoutFinAdmin-container-main-title {
            color: var(--gray700);
            text-align: center;
            margin-bottom: 8px;
            margin-top: 20px;
        }
        .checkoutFinAdmin-container-main-subTitle {
            color: var(--gray700);
            text-align: center;
        }
        .checkoutFinAdmin-container-main-description {
            color: var(--gray700);
            text-align: center;
            margin-top: 32px;
            margin-bottom: 16px;
        }
    .checkoutFinAdmin-container-wave {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
        .checkoutFinAdmin-container-wave-img {
            width: 100%;
        }