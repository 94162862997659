.mainButton-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    background: var(--primary500);
    border-radius: 25px;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    font-family: Lato, sans-serif;
    line-height: 18px;
    color: var(--white);
    margin: 0;
    border: 1px solid var(--primary500);
}
    @media only screen and (max-width: 37.5em) {
        .mainButton-container {
            height: 56px;
            width: 100%;
        }}
    .mainButton-container:active {
        background: var(--primary700);
        border: 1px solid var(--primary700);
        font-size: 0.8125rem;
    }
.mainButton-containerGoogle {
    font-family: Lato, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    background: #E20039;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 18px;
    color: var(--white);
    margin: 0;
    text-align: center;
    border: none;
}
    @media only screen and (max-width: 37.5em) {
        .mainButton-containerGoogle {
            height: 56px;
            width: 100%;
        }}
    .mainButton-containerGoogle:active {
        font-size: 0.8125rem;
    }
.mainButton-containerBorder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border-radius: 25px;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    font-family: Lato, sans-serif;
    line-height: 18px;
    color: var(--white);
    margin: 0;
    background-color: transparent;
}
    @media only screen and (max-width: 37.5em) {
        .mainButton-containerBorder {
            height: 56px;
            width: 100%;
        }}
    .mainButton-containerBorder:active {
        transform: scale(0.95);
    }

.mainButton-containerFill {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border-radius: 25px;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    font-family: Lato, sans-serif;
    line-height: 18px;
    margin: 0;
    color: var(--white);
}
    @media only screen and (max-width: 37.5em) {
        .mainButton-containerFill {
            height: 56px;
            width: 100%;
        }}
    .mainButton-containerFill:active {
        transform: scale(0.95);
    }

.mainButton-containerEmpty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    cursor: pointer;
    font-style: normal;
    border-radius: 25px;
    font-weight: 700;
    font-size: 0.875rem;
    font-family: Lato, sans-serif;
    line-height: 18px;
    margin: 0;
    background-color: transparent;
    border: none;
}
    @media only screen and (max-width: 37.5em) {
        .mainButton-containerEmpty {
            height: 56px;
            width: 100%;
        }}
    .mainButton-containerEmpty:active {
        transform: scale(0.95);
    }