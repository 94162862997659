.FormFieldPersonFinaer-container {
    display: flex;
    flex-direction: column;
}
    @media only screen and (max-width: 37.5em) {
        .FormFieldPersonFinaer-container {
            justify-content: center;
            align-items: center;
            width: 100%;
            align-self: center;
            margin: 0 auto;
        }}
    .FormFieldPersonFinaer-container-main {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldPersonFinaer-container-main {
                flex-direction: column;
            }}
        .FormFieldPersonFinaer-container-main > * {
            width: 23.5%;
        }
            @media only screen and (max-width: 37.5em) {
                .FormFieldPersonFinaer-container-main > * {
                    width: 100%;
                    margin-bottom: 20px;
                }}
            .FormFieldPersonFinaer-container-main-customInput {
                width: 23.5%;
            }
                @media only screen and (max-width: 37.5em) {
                    .FormFieldPersonFinaer-container-main-customInput {
                        width: 100%;
                        margin-bottom: 20px;
                    }}
                .FormFieldPersonFinaer-container-main-customInput-label {
                    display: flex;
                    flex-direction: row;
                }
                    .FormFieldPersonFinaer-container-main-customInput-label-text {
                        color: var(--customTextInputLabel);
                    }
                    .FormFieldPersonFinaer-container-main-customInput-label-requiered {
                        color: var(--statusErrorPrimary);
                    }
                .FormFieldPersonFinaer-container-main-customInput-inputContainer {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                }
                    .FormFieldPersonFinaer-container-main-customInput-inputContainer-inputWrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        border-bottom: 1px solid var(--gray300);
                    }
                        .FormFieldPersonFinaer-container-main-customInput-inputContainer-inputWrapper-prefix {
                            color: var(--gray400);
                            width: fit-content;
                            font-size: 1.25rem;
                        }
                        .FormFieldPersonFinaer-container-main-customInput-inputContainer-inputWrapper-input {
                            border: none;
                            background-color: transparent;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 1.25rem;
                            line-height: 24px;
                            color: var(--gray700);
                            width: 100%;
                            font-family: Lato, sans-serif;
                            padding: 10px;
                            padding-right: 0px;
                            padding-left: 5px;
                        }
                        .FormFieldPersonFinaer-container-main-customInput-inputContainer-inputWrapper-input:focus {
                            outline: none;
                        }
            .FormFieldPersonFinaer-container-main-selectorList {
                width: 23.5%;
            }
                @media only screen and (max-width: 37.5em) {
                    .FormFieldPersonFinaer-container-main-selectorList {
                        width: 100%;
                        margin-bottom: 20px;
                    }}
                .FormFieldPersonFinaer-container-main-selectorList-label {
                    display: flex;
                    flex-direction: row;
                }
                    .FormFieldPersonFinaer-container-main-selectorList-label-text {
                        color: var(--customTextInputLabel);
                    }
                    .FormFieldPersonFinaer-container-main-selectorList-label-requiered {
                        color: var(--statusErrorPrimary);
                    }
                .FormFieldPersonFinaer-container-main-selectorList-selectorWrapper {
                    
                }
    .FormFieldPersonFinaer-container-buttonWrapper {
        width: 15%;
        margin: 20px 0;
        margin-left: auto;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldPersonFinaer-container-buttonWrapper {
                width: 100%;
            }}