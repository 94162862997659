.ofertaPersonalizada-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}
    .ofertaPersonalizada-container-main {
        display: flex;
        flex-direction: column;
        width: 93.5%;
    }
        @media only screen and (max-width: 37.5em) {
            .ofertaPersonalizada-container-main {
                width: 90.5%;
            }
        }
        .ofertaPersonalizada-container-main-title {
            color: var(--primary500);
        }
        .ofertaPersonalizada-container-main-subTitle {
            color: var(--gray400);
        }
        .ofertaPersonalizada-container-main-inputList {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 20px 0;
            gap: 20px;
        }
            @media only screen and (max-width: 37.5em) {
                .ofertaPersonalizada-container-main-inputList {
                    flex-direction: column;
                    flex-wrap: nowrap;
                }
            }
            .ofertaPersonalizada-container-main-inputList > * {
                width: calc(25% - 20px);
            }
                @media only screen and (max-width: 37.5em) {
                    .ofertaPersonalizada-container-main-inputList > * {
                        width: 100%;
                    }
                }
            .ofertaPersonalizada-container-main-inputList-selectorWrapper {
                width: calc(25% - 20px);
                display: flex;
                flex-direction: column;
            }
                @media only screen and (max-width: 37.5em) {
                    .ofertaPersonalizada-container-main-inputList-selectorWrapper {
                        width: 100%;
                    }
                }
                .ofertaPersonalizada-container-main-inputList-selectorWrapper-title {
                    color: var(--customTextInputLabel);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                }
                    .ofertaPersonalizada-container-main-inputList-selectorWrapper-title ul {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 1.25rem;
                        line-height: 24px;
                        color: var(--statusErrorPrimary);
                        margin: 0;
                        padding: 0;
                    }
            .ofertaPersonalizada-container-main-inputList-list {
                display: flex;
                flex-direction: row;
                width: calc(25% - 20px);
                justify-content: space-between;
            }
                @media only screen and (max-width: 37.5em) {
                    .ofertaPersonalizada-container-main-inputList-list {
                        width: 100%;
                    }
                }
                .ofertaPersonalizada-container-main-inputList-list > * {
                    width: calc(33.3% - 20px);   
                }
            .ofertaPersonalizada-container-main-inputList-optionWrapper {
                width: calc(25% - 20px);
                display: flex;
                flex-direction: column;
            }
                @media only screen and (max-width: 37.5em) {
                    .ofertaPersonalizada-container-main-inputList-optionWrapper {
                        width: 100%;
                    }
                }
                .ofertaPersonalizada-container-main-inputList-optionWrapper-title {
                    color: var(--customTextInputLabel);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                }
                    .ofertaPersonalizada-container-main-inputList-optionWrapper-title ul {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 1.25rem;
                        line-height: 24px;
                        color: var(--statusErrorPrimary);
                        margin: 0;
                        padding: 0;
                    }
                .ofertaPersonalizada-container-main-inputList-optionWrapper-options {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                }
                    .ofertaPersonalizada-container-main-inputList-optionWrapper-options-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-right: 20px;
                    }
                        .ofertaPersonalizada-container-main-inputList-optionWrapper-options-item-circle {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 18px;
                            width: 18px;
                            border: 2px solid var(--primary500);
                            border-radius: 15px;
                            margin-right: 5px;
                        }
                            .ofertaPersonalizada-container-main-inputList-optionWrapper-options-item-circle-inner {
                                height: 12px;
                                width: 12px;
                                background-color: var(--primary500);
                                border-radius: 15px;
                                transition: all 0.5s ease;
                            }
                        .ofertaPersonalizada-container-main-inputList-optionWrapper-options-item-text {
                            color: var(--customTextInputLabel);
                        }
        .ofertaPersonalizada-container-main-buttonWrapper {
            width: 20%;
            margin: 50px 0;
            margin-left: auto;
        }
            @media only screen and (max-width: 37.5em) {
                .ofertaPersonalizada-container-main-buttonWrapper {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 20px;
                }
            }