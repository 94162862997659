.burgerMenuItem-container {
    display: flex;
    flex-direction: row;
    padding: 10px 5px;
    border-radius: 15px;
    background-color: transparent;
    width: fit-content;
    margin-bottom: 16px;
    cursor: pointer;
    margin-right: auto;
    margin-left: 10px;
}
    .burgerMenuItem-container-text {
        align-items: center;
        color: var(--white);
    }