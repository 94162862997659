.offerPlan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: var(--white);
    min-width: 320px;
}
    @media only screen and (max-width: 1300px) {
        .offerPlan-container {
            min-width: 260px;
        }
    }
    @media only screen and (max-width: 1200px) {
        .offerPlan-container {
            min-width: 240px;
        }
    }
    @media only screen and (max-width: 1100px) {
        .offerPlan-container {
            min-width: 220px;
        }
    }
    @media only screen and (max-width: 1000px) {
        .offerPlan-container {
            min-width: 200px;
        }
    }
    @media only screen and (max-width: 600px) {
        .offerPlan-container {
            min-width: 320px;
        }
    }
    .offerPlan-container-title {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px 25px 0 0;
        width: 100%;
        padding: 10px 0;
        background-color: var(--primary200);
    }
        .offerPlan-container-title-text {
            color: var(--gray600);
        }
    .offerPlan-container-score {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
    }
    .offerPlan-container-planType {
        color: var(--gray400);
        margin: 8px 0;
    }
    .offerPlan-container-price {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: row;
        width: 100%;
    }
        .offerPlan-container-price-unit {
            color: var(--gray400);
            margin-bottom: 7px;
        }
        .offerPlan-container-price-price {
            color: var(--secondary500);
        }
        .offerPlan-container-price-per {
            color: var(--gray400);
            margin-bottom: 9px;
        }
    .offerPlan-container-line {
        width: 100%;
        height: 1px;
        background-color: var(--gray200);
        margin: 16px 0;
    }
    .offerPlan-container-queCubre {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        width: 90%;
    }
        .offerPlan-container-queCubre-title {
            color: var(--gray400);
            margin-bottom: 12px;
        }
        .offerPlan-container-queCubre-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
            .offerPlan-container-queCubre-list-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 12px;
                width: 100%;
            }
                .offerPlan-container-queCubre-list-item-title {
                    color: var(--gray600);
                }
                .offerPlan-container-queCubre-list-item-descripcion {
                    color: var(--gray600);
                }
    .offerPlan-container-boton {
        width: 90%;
        margin: 0px auto;
        margin-bottom: 10px;
    }