.checkout-container {
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}
    .checkout-container–topContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        background-color: var(--checkoutHeader);
        height: 114px;
    }
        @media only screen and (max-width: 37.5em) {
            .checkout-container–topContainer {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 70px;
            }}
        .checkout-container–topContainer-left {
            display: flex;
            flex-direction: column;
            width: 46%;
            margin-left: 50px;
        }
            @media only screen and (max-width: 37.5em) {
                .checkout-container–topContainer-left {
                    width: 92%;
                    margin-left: 0;
                    align-items: center;
                }}
            .checkout-container–topContainer-left-pasoText {
                color: var(--gray700);
                margin-bottom: 2px;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkout-container–topContainer-left-pasoText {
                    }}
            .checkout-container–topContainer-left-titleText {
                margin-top: 2px;
                color: var(--gray700);
            }
                @media only screen and (max-width: 37.5em) {
                    .checkout-container–topContainer-left-titleText {
                        text-align: center;
                    }}
        .checkout-container–topContainer-right {
            display: flex;
            width: 46%;
        }
            @media only screen and (max-width: 37.5em) {
                .checkout-container–topContainer-right {
                    width: 90%;
                }}
    .checkout-container–topMask {
        display: flex;
        background: var(--checkoutHeader);
        height: 100px;
        width: 100%;
    }
        .checkout-container–topMask-inner {
            display: flex;
            background: var(--gray50);
            height: 100px;
            width: 100%;
            border-top-right-radius: 50px;
        }
    .checkout-container–main {
        margin-top: 20px;
    }
    .checkout-container-blockScreen {
        position:fixed;
        padding:0;
        margin:0;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
    }