.politicaPage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}
    .politicaPage-container-main {
        display: flex;
        flex-direction: column;
        width: 93.5%;
    }
        @media only screen and (max-width: 37.5em) {
            .politicaPage-container-main {
                width: 90.5%;
            }
        }
        .politicaPage-container-main-goBack {
            display: flex;   
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            width: fit-content;
        }
            .politicaPage-container-main-goBack-text {
                color: var(--primary500);
            }
        .politicaPage-container-main-textContainer {
            margin-top: 17px;
        }
            .politicaPage-container-main-textContainer-title {
                margin-bottom: 13px;
                color: var(--primary500);
            }