.ofertaPersonalizadaEnviado-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}
    .ofertaPersonalizadaEnviado-container-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 94%;
        margin: 100px 0;
    }
        .ofertaPersonalizadaEnviado-container-main-title {
            color: var(--primary500);
            margin-bottom: 5px;
            margin-top: 24px;
        }
        .ofertaPersonalizadaEnviado-container-main-subTitle {
            color: var(--gray700);
        }
        .ofertaPersonalizadaEnviado-container-main-buttonWrapper {
            width: 15%;
            margin-top: 24px;
        }
            @media only screen and (max-width: 37.5em) {
                .ofertaPersonalizadaEnviado-container-main-buttonWrapper {
                    width: 50%;
                }
            }