.accountBankArCompleteConfigComponent-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 75%;
  margin-top: 20px;
}
  @media only screen and (max-width: 37.5em) {
    .accountBankArCompleteConfigComponent-container {
      width: 95%;
    }}
  .accountBankArCompleteConfigComponent-container > * {
    width: 47.5%;
    margin-bottom: 30px;
  }
    @media only screen and (max-width: 37.5em) {
      .accountBankArCompleteConfigComponent-container > * {
        width: 100%;
      }}
  .accountBankArCompleteConfigComponent-container-selectorWrapper {
    display: flex;
    flex-direction: column;
    width: 47.5%;
    margin-bottom: 30px;
}
    @media only screen and (max-width: 37.5em) {
        .accountBankArCompleteConfigComponent-container-selectorWrapper {
            width: 100%;
        }}
    .accountBankArCompleteConfigComponent-container-selectorWrapper-title {
        color: var(--customTextInputLabel);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
    }
    .accountBankArCompleteConfigComponent-container-selectorWrapper-title ul {
        font-style: normal;
        font-weight: bold;
        font-size: 1.25rem;
        line-height: 24px;
        color: var(--statusErrorPrimary);
        margin: 0;
        padding: 0;
    }