.otherSection-container {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 42px;
}
    .otherSection-container-img {
        height: 100%;
        width: 100%;
        position: relative;
        object-fit: cover;
        object-position: center;
        margin: 0 auto;
    }
    .otherSection-container-content {
        position: absolute;
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
        @media only screen and (max-width: 37.5em) {
            .otherSection-container-content {
                justify-content: space-between;
                height: 90%;
            }
        }
        .otherSection-container-content-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
            .otherSection-container-content-text-title {
                color: var(--accent400);
                text-align: center;
            }
            .otherSection-container-content-text-subtitle {
                color: var(--accent400);
                margin-top: 8px;
                margin-bottom: 32px;
                text-align: center;
            }
                @media only screen and (max-width: 37.5em) {
                    .otherSection-container-content-text-subtitle {
                        width: 90%;
                    }
                }
        .otherSection-container-content-botton {
            width: 20%;
        }
            @media only screen and (max-width: 37.5em) {
                .otherSection-container-content-botton {
                    width: 90%;
                }
            }