.discountDetailsComponent-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    @media only screen and (max-width: 37.5em) {
        .discountDetailsComponent-container {

        }}
    .discountDetailsComponent-container-code {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        padding-top: 7.5px;
        padding-bottom: 5px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: var(--gray200);
    }
        @media only screen and (max-width: 37.5em) {
            .discountDetailsComponent-container-code {
                margin-top: 0px;
            }}
        .discountDetailsComponent-container-code-apply {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            width: 24px;
            background-color: var(--secondary500);
            border-radius: 25px;
            cursor: pointer;
        }
        .discountDetailsComponent-container-code-misapply {
            cursor: pointer;
        }
            .discountDetailsComponent-container-code-misapply-text {
                color: var(--secondary500);
            }
        .discountDetailsComponent-container-codeSuccessBox {
            background: var(--white);
            border: 2px solid var(--gray200);
            box-sizing: border-box;
            border-radius: 25px;
        }
        .discountDetailsComponent-container-code-input {
            border: none;
            background-color: transparent;
            font-style: normal;
            font-weight: normal;
            font-size: 0.9375rem;
            line-height: 32px;
            color: var(--secondary500);
            width: 100%;
            margin-left: 20px;
        } 
            @media only screen and (max-width: 37.5em) {
                .discountDetailsComponent-container-code-input {
                    font-size: 0.8125rem;
                    margin-left: 5px;
                }}
            .discountDetailsComponent-container-code-input::placeholder {
                font-style: normal;
                font-weight: normal;
                font-size: 0.9375rem;
                line-height: 32px;
                color: var(--secondary500);
            }
                @media only screen and (max-width: 37.5em) {
                    .discountDetailsComponent-container-code-input::placeholder {
                        font-size: 0.8125rem;
                    }}
            .discountDetailsComponent-container-code-input:focus::placeholder {
                opacity: 0.3;
            }
            .discountDetailsComponent-container-code-input:focus {
                outline: none;
            }
        .discountDetailsComponent-container-code-deleteCodeText {
            font-style: normal;
            font-weight: normal;
            font-size: 0.9375rem;
            line-height: 32px;
            color: var(--fifthTextColor);
            margin: 0;
        }
    .discountDetailsComponent-container-codeLine {
        height: 1px;
        width: 100%;
        background-color: var(--gray200);
        opacity: 0.5;
    }
    .discountDetailsComponent-container-codeError {
        font-style: normal;
        font-weight: 500;
        font-size: 0.9375rem;
        line-height: 24px;
        color: red;
        margin: 0;
        margin-left: 20px;
    }
    .discountDetailsComponent-container-codeSuccess {
        font-style: normal;
        font-weight: 500;
        font-size: 0.9375rem;
        line-height: 24px;
        color: #27AE60;
        margin: 0;
        margin-top: 10px;
        margin-left: 20px;
    }
    .discountDetailsComponent-container-price {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0;
    }
        .discountDetailsComponent-container-price-text {
            color: var(--secondary500);
        }
        .discountDetailsComponent-container-price-price {
            text-align: right;
            color: var(--secondary500);
        }