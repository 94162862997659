.login-container {
    background-color: var(--adminInicioSesionFondo);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    overflow-y: auto;
}
    .login-container-main{
        display: flex; 
        flex-direction: column;
        align-items: center;
        max-width: 427px;
        margin: 20px auto;
    }
        @media only screen and (max-width: 37.5em) {
            .login-container-main{
                max-width: unset;
                width: 90%;
            }}
        .login-container-main-logos {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 32px;
        }
            @media only screen and (max-width: 37.5em) {
                .login-container-main-logos{
                    margin-bottom: 16px;
                    margin-top: 88px;
                }}
                .login-container-main-logos-img{
                    height: 40px;
                    margin-right: 10px;
                }
                @media only screen and (max-width: 37.5em) {
                    .login-container-main-logos-img{
                        height: 30px;
                    }
                }
        .login-container-main-title {
            text-align: center;
            color: var(--primary500);
            margin-bottom: 32px;
        }
            @media only screen and (max-width: 37.5em) {
                .login-container-main-title {
                    margin-bottom: 56px;
                }}
        .login-container-main-subtitle{
            text-align: center;
            color: var(--gray700);
            margin-bottom: 32px;
        }
        .login-container-main-inputs{
            display: flex;
            flex-direction: column;
            width: 80%;
        }
            @media only screen and (max-width: 37.5em) {
                .login-container-main-inputs{
                    width: 100%;
                }}
            .login-container-main-inputs > *{
                margin-bottom: 32px;
            }
        .login-container-main-pass{
            text-align: center;
            color: var(--adminInicioSesionOlvido);
            cursor: pointer;
            margin-bottom: 32px;
        }
            @media only screen and (max-width: 37.5em) {
                .login-container-main-pass{
                    margin-bottom: 16px;
                }}
        .login-container-main-button {
            width: 75%;
            margin-bottom: 32px;
        }
            @media only screen and (max-width: 37.5em) {
                .login-container-main-button {
                    width: 100%;
                }}
        .login-container-main-marcador{
            background: var(--accent400);
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            display: flex;
            flex-direction: row;
            width: 380px;
            align-items: center;
            justify-content: space-evenly;
            padding: 24px 0;
        }
            @media only screen and (max-width: 37.5em) {
                .login-container-main-marcador{
                    width: 100%;
                }}
            .login-container-main-marcador-text {
                width: 80%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
                .login-container-main-marcador-text-title {
                    color: var(--white);
                }
                .login-container-main-marcador-text-subTitle {
                    color: var(--white);
                    margin-top: 6px;
                }