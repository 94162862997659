.FormFieldList-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    .FormFieldList-container-title {
        color: var(--gray700);
        margin-top: 24px;
        margin-bottom: 16px;
    }
    .FormFieldList-container-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
        .FormFieldList-container-empty-title {
            text-align: center;
            color: var(--black);
            margin: 10px 0;
        }
        .FormFieldList-container-empty-text {
            text-align: center;
            color: var(--black);
        }
    .FormFieldList-container-buscador {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: var(--white);
        border: 1px solid var(--gray200);
        border-radius: 15px;
        padding: 10px;
        margin-bottom: 20px;
    }
        .FormFieldList-container-buscador-input {
            outline: none;
            border: none;
            width: 90%;
        }
        .FormFieldList-container-buscador-input::placeholder {
            color: var(--gray400);
        }
    .FormFieldList-container-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        width: 100%;
        gap: 20px; 
        overflow-y: auto;
        height: 100%;
        padding-top: 20px;
    }
        @media only screen and (max-width: 37.5em) {
            .FormFieldList-container-list {
                flex-direction: column;
        }}

.FormFieldList-container-loader {
    width: 20px;
    height: 20px;
    border: 2px solid var(--primary500);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-left: auto;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 