.FormFieldListItem-container {
    display: flex;
    border-bottom: 1px solid var(--primary200);
    cursor: pointer;
    margin-top: -20px;
    color: var(--gray700);
}
    @media only screen and (max-width: 37.5em) {
        .FormFieldListItem-container {
            width: 100%;
    }}
.FormFieldListItem-container:hover {
    border-bottom: 1px solid var(--primary200);
    background-color: var(--primary200);
}
.FormFieldListItem-container:active {
    border-bottom: 1px solid var(--primary500);
    background-color: var(--primary500);
    color: var(--white);
}
    .FormFieldListItem-container-text {
        padding-bottom: 8px;
        padding-left: 8px;
        padding-top: 8px;
    }
    .FormFieldListItem-container-texts {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        padding-bottom: 8px;
        padding-left: 8px;
        padding-top: 8px;
        cursor: pointer;
    }
        .FormFieldListItem-container-texts-text {
            color: var(--gray700);
        }
        .FormFieldListItem-container-texts-subText {
            color: var(--gray400);
        }