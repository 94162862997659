.checkOutProfile-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 50px;
}
    @media only screen and (max-width: 37.5em) {
        .checkOutProfile-container {
            width: 95%;
            flex-direction: column;
        }}
    .checkOutProfile-container-endWrapper {
        display: flex;
        flex-direction: column;
        width: 30%;
        margin-right: 20px;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutProfile-container-endWrapper {
                width: 100%;
                position: fixed;
                bottom: 0;
                background-color: var(--white);
                z-index: 20;
                align-items: center;
                justify-content: center;
                box-shadow: 0px -4px 30px 0px rgba(0, 0, 0, 0.10);
            }}
        .checkOutProfile-container-endWrapper-main {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
            @media only screen and (max-width: 37.5em) {
                .checkOutProfile-container-endWrapper-main {
                    width: 90%;
                    margin-bottom: 10px;
                }}
    .checkOutProfile-container-main {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin-left: 50px;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutProfile-container-main {
                width: 91%;
                margin-left: 25px;
                padding-bottom: 120px;
            }}
        .checkOutProfile-container-main-loginContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 24px;
        }
            .checkOutProfile-container-main-loginContainer-text {
                color: var(--gray700);
            }
            .checkOutProfile-container-main-loginContainer-click {
                color: var(--primary500);
                cursor: pointer;
            }
        .checkOutProfile-container-main-ubicacionConfirm {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 50px;
        }
            .checkOutProfile-container-main-ubicacionConfirm-square {
                height: 18px;
                width: 18px;
                border: 2px solid var(--primary500);
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                margin-right: 5px;
                cursor: pointer;
            }
                .checkOutProfile-container-main-ubicacionConfirm-square-inner {
                    height: 18px;
                    width: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--primary500);
                    transition: all 0.5s ease;
                }
            .checkOutProfile-container-main-ubicacionConfirm-text {
                color: var(--gray500);
            }
        .checkOutProfile-container-main-titulo {
            color: var(--gray700);
            margin-bottom: 24px;
        }
        .checkOutProfile-container-main-volver {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--primary500);
            margin-bottom: 24px;
            cursor: pointer;
            width: fit-content;
        }
        .checkOutProfile-container-main-reLogText {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-right: auto;
            margin-bottom: 24px;
        }
            .checkOutProfile-container-main-reLogText p {
                color: var(--primary500);
                cursor: pointer;
                font-weight: 700;
            }

        .checkOutProfile-container-main-list {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 97.5%;
        }
            @media only screen and (max-width: 37.5em) {
                .checkOutProfile-container-main-list {
                    flex-direction: column;
                    align-items: center;
                    flex-wrap: nowrap;
                    width: 100%;
                }}
            .checkOutProfile-container-main-list-list {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 31%;
            }
                .checkOutProfile-container-main-list-list > * {
                    width: 31%;
                }
            .checkOutProfile-container-main-list-invis {
                width: 31%;
                margin-bottom: 30px;
            }
            .checkOutProfile-container-main-list > * {
                width: 31%;
                margin-bottom: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutProfile-container-main-list > * {
                        width: 100%;
                    }}
            .checkOutProfile-container-main-list-item-specialInput {
                display: flex;   
                flex-direction: column;
                justify-content: flex-start;
                width: 30%;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutProfile-container-main-list-item-specialInput {
                        width: 100%;
                    }}
                .checkOutProfile-container-main-list-item-specialInput-label {
                    display: flex;
                    flex-direction: row;
                }
                    .checkOutProfile-container-main-list-item-specialInput-label-text {
                        color: var(--tertiary500);
                    }
                    .checkOutProfile-container-main-list-item-specialInput-label-requiered {
                        color: var(--statusErrorPrimary);
                    }
                .checkOutProfile-container-main-list-item-specialInput-main {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                    .checkOutProfile-container-main-list-item-specialInput-main-input {
                        border: none;
                        background-color: transparent;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 1.25rem;
                        line-height: 24px;
                        color: var(--black);
                        width: 100%;
                        font-family: Lato, sans-serif;
                        padding: 10px;
                        border-bottom: 1px solid var(--gray300);
                    }
                    .checkOutProfile-container-main-list-item-specialInput-main-input:focus {
                        outline: none;
                    }
                    .checkOutProfile-container-main-list-item-specialInput-main-input::placeholder {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 1rem;
                        line-height: 1.5rem;                
                        color: var(--gray300);
                        font-family: Lato, sans-serif;
                    }
                    .checkOutProfile-container-main-list-item-specialInput-main-line {
                        color: var(--gray300);
                        margin: 0 10px;
                    }
                    .checkOutProfile-container-main-list-item-specialInput-main-status {
                        margin-left: -20px;
                    }
            .checkOutProfile-container-main-list-item-optionWrapper {
                display: flex;
                flex-direction: column;
                width: 31%;
                margin-bottom: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutProfile-container-main-list-item-optionWrapper {
                        width: 100%;
                    }}
                .checkOutProfile-container-main-list-item-optionWrapper-title {
                    color: var(--customTextInputLabel);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                }
                .checkOutProfile-container-main-list-item-optionWrapper-title ul {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.25rem;
                    line-height: 24px;
                    color: red;
                    margin: 0;
                    padding: 0;
                }
                .checkOutProfile-container-main-list-item-optionWrapper-list {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }
                    .checkOutProfile-container-main-list-item-optionWrapper-list-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
                        .checkOutProfile-container-main-list-item-optionWrapper-list-item-circle {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 18px;
                            width: 18px;
                            border-radius: 25px;
                            margin-right: 5px;
                            cursor: pointer;
                        }
                            .checkOutProfile-container-main-list-item-optionWrapper-list-item-circle-inner {
                                height: 12px;
                                width: 12px;
                                background-color: var(--secondary500);
                                border-radius: 25px;
                                transition: all 0.5s ease;
                            }  
                        .checkOutProfile-container-main-list-item-optionWrapper-list-item-text {
                            color: var(--gray500);
                        } 
            .checkOutProfile-container-main-list-item-selectorWrapper {
                display: flex;
                flex-direction: column;
                width: 31%;
                margin-bottom: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutProfile-container-main-list-item-selectorWrapper {
                        width: 100%;
                    }}
                .checkOutProfile-container-main-list-item-selectorWrapper-title {
                    color: var(--customTextInputLabel);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                }
                .checkOutProfile-container-main-list-item-selectorWrapper-title ul {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.25rem;
                    line-height: 24px;
                    color: var(--statusErrorPrimary);
                    margin: 0;
                    padding: 0;
                }
            .checkOutProfile-container-main-list-item-customInput {
                width: 31%;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutProfile-container-main-list-item-customInput {
                        width: 100%;
                        margin-bottom: 20px;
                    }}
                .checkOutProfile-container-main-list-item-customInput-label {
                    display: flex;
                    flex-direction: row;
                }
                    .checkOutProfile-container-main-list-item-customInput-label-text {
                        color: var(--customTextInputLabel);
                    }
                    .checkOutProfile-container-main-list-item-customInput-label-requiered {
                        color: var(--statusErrorPrimary);
                    }
                .checkOutProfile-container-main-list-item-customInput-inputContainer {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                }
                    .checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        border-bottom: 1px solid var(--gray300);
                    }
                        .checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper-prefix {
                            color: var(--gray400);
                            width: fit-content;
                        }
                        .checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper-input {
                            border: none;
                            background-color: transparent;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 1.25rem;
                            line-height: 24px;
                            color: var(--gray700);
                            width: 100%;
                            font-family: Lato, sans-serif;
                            padding: 10px;
                            padding-right: 0px;
                            padding-left: 5px;
                        }
                        .checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper-input:focus {
                            outline: none;
                        }
            .checkOutProfile-container-main-list-innerList {
                display: flex;
                flex-direction: row;
                width: 103%;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutProfile-container-main-list-innerList {
                        width: 105%;
                    }}