@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./fonts/Lato-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  src: local('Lato'), url(./fonts/Lato-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  src: local('Lato'), url(./fonts/Lato-Black.ttf) format('opentype');
}

html {
  font-size: 1vw;
  overflow-x: hidden;
  scrollbar-width: thin; /* Options: auto | thin | none */
  scrollbar-color: #888 #f1f1f1; /* Scrollbar color and track color */
}
  @media only screen and (max-width: 37.5em) {
      html {
          font-size: 4.2vw;
      }}
  @media only screen and (max-width: 37.5em) and (min-width: 400px) {
      html {
          font-size: 3.5vw;
      }}
  @media only screen and (max-width: 900px) and (min-width: 37.5em) {
      html {
          font-size: 1.6vw;
      }}
  @media only screen and (max-width: 1200px) and (min-width: 900px) {
      html {
          font-size: 1.2vw;
      }}
  @media only screen and (min-width: 1900px) {
      html {
          font-size: unset;
      }}

.coverLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999999999999999999;
  width: 100%;
  height: 100%;
}

:root {
  
  /* Colores Nuevos */

  --primary200: #FEDEDC;
  --primary300: #FFC8C4;
  --primary400: #EC3843;
  --primary500: #E30613;
  --primary700: #AD0912;
  --primary800: #620207;

  --secondary200: #DAEEFF;
  --secondary300: #C4E5FF;
  --secondary400: #76A8D1;
  --secondary500: #4D8DC0;
  --secondary700: #3881BD;
  --secondary800: #316C9B;

  --accent200: #FED287;
  --accent300: #3AE6DF;
  --accent400: #2f3743;

  --statusSuccessPrimary: #008744;
  --statusSuccessSecondary: #00A650;
  --statusWarningPrimary: #E6540B;
  --statusWarningSecondary: #FF7733;
  --statusErrorPrimary: #D12440;
  --statusErrorSecondary: #F23D4F;

  --white: #FFFFFF;
  --gray50: #F4F4F4;
  --gray100: #E6E6E6;
  --gray200: #C4C4C4;
  --gray300: #999999;
  --gray400: #858585;
  --gray500: #666666;
  --gray600: #4D4D4D;
  --gray700: #333333;
  --gray800: #1A1A1A;
  --gray900: #0A0A0A;
  --black: #333333;

  /* Colores Especificos */

  --primaryGradientColor: var(--primary200);
  --secondaryGradientColor: var(--primary200);
  --thirdGradientColor: var(--primary200);

  --inputLabel: var(--gray500);
  --checkoutHeader: var(--primary200);
  --checkoutPaso: var(--white);
  --checkoutTitle: var(--white);
  --stepComplete: var(--white);
  --stepIncomplete: var(--primary700);
  --checkoutFinal: var(--secondary500);
  --checkoutPrice: var(--secondary500);
  --adminInicioSesionFondo: var(--gray50);
  --adminInicioSesionTexto: var(--white);
  --adminInicioSesionOlvido: var(--primary500);
  --adminInicioSesionMarcadoresGrad1: #DE2D61;
  --adminInicioSesionMarcadoresGrad2: #FF7A41;
  --customTextInputLabel: var(--gray500);

}

h1 {
  font-family: PT Sans, sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 3rem;
  margin: 0;
}
@media only screen and (max-width: 390px) {
  h1 {
    font-size: 1.7rem;
    line-height: 2.25rem;
  }
}
@media only screen and (min-width: 390px) and (max-width: 38.5em) {
  h1 {
    font-size: 2.125rem;
    line-height: 2.75rem;
  }
}


h2 {
  font-family: PT Sans, sans-serif;
  font-style: normal;
  font-size: 2.125rem;
  line-height: 2.75rem;
  margin: 0;
}
@media only screen and (max-width: 38.5em) {
  h2 {
    font-size: 1.7rem;
    line-height: 2.25rem;
  }
}


h3 {
  font-style: normal;
  font-size: 1.75rem;
  line-height: 2.25rem;
  margin: 0;
}

h4 {
  font-family: PT Sans, sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;
}
@media only screen and (max-width: 38.5em) {
  h4 {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
}


h5 {
  font-style: normal;
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin: 0;
}

h6 {
  font-style: normal;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 0;
}

p {
  font-style: normal;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 0;
}

b {
  font-style: normal;
  font-size: 1rem;
  line-height: 1.3125rem;
  margin: 0;
}
@media only screen and (max-width: 38.5em) {
  b {
    font-size: 0.9rem;
    line-height: 1.25rem;
  }
}

.large {
  font-style: normal;
  font-size: 1rem;
  line-height: 1.313rem;
}
  @media only screen and (max-width: 38.5em) {
    .large {
      font-size: 0.9rem;
      line-height: 1.25rem;
    }
  }

.medium {
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1rem;
}

.small {
  font-style: normal;
  font-size: 0.75rem;
  line-height: 0.875rem;
}

.xSmall {
  font-style: normal;
  font-size: 0.625rem;
  line-height: 0.75rem;
}

.bold {
  font-weight: 700;
}

.regular {
  font-weight: 400;
}

.light {
  font-weight: 300;
}

::-webkit-scrollbar {
  display: none;
}
