.selector-container {
    width: 100%;
}
    @media only screen and (max-width: 37.5em) {
        .selector-container {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }}
    .selector-container-selector {
        display: flex;
        transition: height 0.6s ease-in;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: transparent;
        box-sizing: border-box;
        border-radius: 25px;
        padding: 10px 20px;
    }
        .selector-container-selector-value {
            font-style: normal;
            font-weight: normal;
            font-size: 1.125rem;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: var(--primary500);
            margin: 0;
            padding: 0;
            border: 0;
            background: transparent;
            outline: 0;
            width: 92.5%;
        }
        .selector-container-selector-lupa {
            transition: all 0.6s ease-in;
            display: flex;
            background: var(--primary500);
            border-radius: 0px 25px 25px 0px;
            align-items: center;
            justify-content: center;
            padding: 10px;
            width: 7.5%;
        }
    .selector-container-list { 
        transition: all 0.6s ease-in;
        overflow-y: auto;
        background: var(--white);
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        border-radius: 0px 0px 25px 25px;
        position: absolute;
        z-index: 10;
    }
        .selector-container-list-item {
            font-style: normal;
            font-weight: normal;
            font-size: 1.125rem;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: var(--primary500);
            margin: 0;
            cursor: pointer;
            padding: 10px 0;
            padding-left: 25px;
        }
            .selector-container-list-item:active {
                background-color: var(--primary500);
                color: var(--white)
            }
            .selector-container-list-item:hover {
                background-color: var(--gray100);
            }