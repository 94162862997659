.cotizar-container {
      padding:0;
      margin:0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      position: fixed;
}
      .cotizar-container-mainLoading {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 80%;
      }
            @media only screen and (max-width: 37.5em) {
                  .cotizar-container-mainLoading {
                        height: 100%;
                  }}
            .cotizar-container-mainLoading-text {
                  text-align: center;
                  color: var(--secondary500);
                  margin-top: 20px;
            }
      .cotizar-container-main {
            display: flex;
            flex-direction: column;
            align-self: center;
            justify-content: flex-start;
            margin-bottom: 20px;
            min-height: 600px;
            max-height: 600px;
            overflow-y: auto;
      }